import { useEffect } from 'react';

export function useExternalStylesheet(url: string): void {
  useEffect(() => {
    const styleSheet = document.createElement('link');
    styleSheet.href = url;
    styleSheet.rel = 'stylesheet';
    styleSheet.type = 'text/css';

    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [url]);
}
