import { useEffect } from 'react';

export function useExternalScript(url: string, callback?: () => void): void {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;

    /*
      The `async` attribute only affects execution; the scripts may still be loaded in parallel.
      
      We need the execution to be sequential to ensure that a script doesn't encounter errors
      because it depends on a chunk that hasn't finished loading.

      If we want to make this hook more reusable, we should consider allowing calling code
      to specify this value.
    */
    script.async = false;

    if (callback) {
      script.addEventListener('load', callback);
    }

    document.body.appendChild(script);

    return () => {
      /* istanbul ignore next */
      if (callback) {
        script.removeEventListener('load', callback);
      }
      document.body.removeChild(script);
    };
  }, [url]);
}
