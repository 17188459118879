/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as React from 'react';
import MaterialButton, { ButtonProps as MaterialButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { defaultTheme } from '../../styles/defaultTheme';
import { Palette } from '../../styles/palette';
import { Typography } from '../Typography/Typography';

export type TButtonPriority = 'primary' | 'secondary' | 'tertiary';
export type TButtonEffect = 'action' | 'attention' | 'save' | 'remove';
export type TButtonSize = 'regular' | 'compact';
export type TButtonState = 'default' | 'hover' | 'focus' | 'active' | 'disabled';

const colorsByEffect: TColorsByEffect = {
  action: {
    default: Palette.primaryDark,
    focus: Palette.primaryDarker,
    hover: Palette.primaryDarker,
    active: Palette.primaryDark,
  },
  attention: {
    default: Palette.attention,
    focus: Palette.attentionDark,
    hover: Palette.attentionDark,
    active: Palette.attention,
  },
  save: {
    default: Palette.positive,
    focus: Palette.positiveDark,
    hover: Palette.positiveDark,
    active: Palette.positive,
  },
  remove: {
    default: Palette.danger,
    focus: Palette.dangerDark,
    hover: Palette.dangerDark,
    active: Palette.danger,
  },
};

const tertiaryColorsByEffect: TColorsByEffect = {
  action: { default: Palette.primaryDark },
  attention: { default: Palette.attentionDark },
  save: { default: Palette.positiveDark },
  remove: { default: Palette.dangerDark },
};

const variantsByPriority: TVariantPriorityMap = {
  primary: 'contained',
  secondary: 'outlined',
  tertiary: 'text',
};

export type TButtonProps = Pick<
  MaterialButtonProps,
  | 'disabled'
  | 'fullWidth'
  | 'href'
  | 'id'
  | 'onClick'
  | 'onKeyPress'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'title'
  | 'type'
  | 'autoFocus'
  | 'children'
> & {
  priority?: TButtonPriority;
  effect?: TButtonEffect;
  size?: TButtonSize;
} & { additionalClassNames?: string };

const useStyles = makeStyles<unknown, TButtonProps>({
  root: {
    borderRadius: 4,
    lineHeight: ({ size }) => (size === 'compact' ? '16px' : '20px'),
    textTransform: 'none',
    boxShadow: 'none',
    '&:active': {
      boxShadow: 'none',
    },
    '&$disabled': {
      color: defaultTheme.palette.darkText.lighter,
    },
    // FF applies both of these pseudo class selectors if a focused button gets disabled.
    '&$disabled:focus': {
      boxShadow: 'none',
    },
  },
  primary: {
    padding: ({ size }) => (size === 'compact' ? '4px 16px' : '8px 20px'),
    backgroundColor: ({ effect = 'action' }) => colorsByEffect[effect].default,
    '&:focus': {
      backgroundColor: ({ effect = 'action' }) => colorsByEffect[effect].focus,
      boxShadow: `0px 2px 2px ${defaultTheme.palette.darkText.lighter}`,
    },
    '&:hover': {
      backgroundColor: ({ effect = 'action' }) => colorsByEffect[effect].hover,
      // Color value is present to override a:hover in app.cs
      color: defaultTheme.palette.lightText.main,
    },
    '&:active': {
      backgroundColor: ({ effect = 'action' }) => colorsByEffect[effect].active,
    },
    '&$disabled, &:hover$disabled, &:focus$disabled, &:active$disabled': {
      backgroundColor: defaultTheme.palette.gray.light,
    },
  },
  secondary: {
    borderWidth: 1,
    borderStyle: 'solid',
    padding: ({ size }) => (size === 'compact' ? '3px 15px' : '7px 19px'),
    backgroundColor: defaultTheme.palette.white,
    borderColor: ({ effect = 'action' }) => colorsByEffect[effect].default,
    '&:hover, &:focus, &:active': {
      color: defaultTheme.palette.lightText.main,
    },
    '&$disabled, &:hover$disabled, &:focus$disabled, &:active$disabled': {
      color: defaultTheme.palette.darkText.lighter,
      backgroundColor: defaultTheme.palette.white,
      borderColor: defaultTheme.palette.gray.light,
    },
    '&:hover': {
      borderColor: ({ effect = 'action' }) => colorsByEffect[effect].hover,
      backgroundColor: ({ effect = 'action' }) => colorsByEffect[effect].hover,
    },
    '&:focus': {
      borderColor: ({ effect = 'action' }) => colorsByEffect[effect].focus,
      backgroundColor: ({ effect = 'action' }) => colorsByEffect[effect].focus,
      boxShadow: `0px 2px 2px ${defaultTheme.palette.darkText.lighter}`,
    },
    '&:active': {
      borderColor: ({ effect = 'action' }) => colorsByEffect[effect].active,
      backgroundColor: ({ effect = 'action' }) => colorsByEffect[effect].active,
    },
  },
  tertiary: {
    border: '2px solid transparent',
    padding: ({ size }) => (size === 'compact' ? '2px 14px' : '6px 10px'),
    backgroundColor: 'transparent',
    fontSize: 14,
    '&:hover, &:active': {
      backgroundColor: defaultTheme.palette.gray.lighter,
    },
    '&:focus': {
      borderColor: defaultTheme.palette.primary.light,
      backgroundColor: defaultTheme.palette.gray.lighter,
    },
    '&$disabled, &:hover$disabled, &:focus$disabled, &:active$disabled': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  disabled: {},
});

export function getColor(priority: TButtonPriority, effect: TButtonEffect): Palette | undefined {
  switch (priority) {
    case 'primary':
      return Palette.lightText;
    case 'secondary':
      return colorsByEffect[effect].default;
    case 'tertiary':
      return tertiaryColorsByEffect[effect].default;
    default:
      return undefined;
  }
}

const Button: React.FC<TButtonProps> = (props) => {
  const {
    children,
    additionalClassNames = '',
    priority = 'primary',
    effect = 'action',
    size = 'regular',
    disabled,
    ...rest
  } = props;

  const styles = useStyles(props);

  const className = clsx({
    [styles.primary]: priority === 'primary',
    [styles.secondary]: priority === 'secondary',
    [styles.tertiary]: priority === 'tertiary',
  });

  return (
    <MaterialButton
      classes={{ root: styles.root, disabled: styles.disabled }}
      className={`${className} ${additionalClassNames}`}
      variant={variantsByPriority[priority]}
      disableRipple
      disableFocusRipple
      size={size === 'compact' ? 'small' : 'medium'}
      disabled={disabled}
      {...rest}
    >
      <Typography
        variant={size === 'compact' ? 'buttonCompact' : 'button'}
        color={getColor(priority, effect)}
      >
        {children}
      </Typography>
    </MaterialButton>
  );
};

export { Button };

// #region TS helper type
type PickRequired<T, K extends keyof T> = Pick<T, K> & Partial<Exclude<T, K>>;

type TColorsByState = PickRequired<Record<TButtonState, Palette>, 'default'>;

type TColorsByEffect = Record<TButtonEffect, TColorsByState>;

type TMaterialButtonVariant = Pick<MaterialButtonProps, 'variant'>[keyof Pick<
  MaterialButtonProps,
  'variant'
>];

type TVariantPriorityMap = Record<TButtonPriority, TMaterialButtonVariant>;

// #endregion
