import * as React from 'react';
import { last } from 'lodash';

import { useExternalStylesheet } from '../../hooks/useExternalStylesheet';
import { useExternalScript } from '../../hooks/useExternalScript';
import LocalizationService from '../../services/localizationService';

type TIntegratedContentProps = {
  contentParentId: string;
};

const contentKey = 'ASPSignupFormSidebar';

export function IntegratedContent({ contentParentId }: TIntegratedContentProps): JSX.Element {
  const entryPoints = window.env.INTEGRATED_CONTENT_ENTRY_POINTS.split(',');
  const baseUrl = window.env.INTEGRATED_CONTENT_BASE_URL;

  const locale = LocalizationService.currentLanguage;

  function callback(): void {
    window.IntegratedContent.render(contentKey, locale, contentParentId);
  }

  entryPoints.forEach((entryPoint) => {
    const url = `${baseUrl}/${entryPoint}`;

    if (entryPoint.toLowerCase().endsWith('.css')) {
      useExternalStylesheet(url);
    } else if (entryPoint.toLowerCase().endsWith('.js')) {
      if (entryPoint === last(entryPoints)) {
        useExternalScript(url, callback);
      } else {
        useExternalScript(url);
      }
    }
  });

  return (
    <div id={`integrated_content_${contentParentId}`} data-testid={`auto_site_${contentParentId}`}>
      <div id={contentParentId}>
        {/* UI rendered by the Integrated Content app should appear as a child of this node. */}
      </div>
    </div>
  );
}
